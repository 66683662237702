import { Auth } from 'aws-amplify'
import { Button, Form, Input, Modal } from 'antd'
import React, { Component } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import uuid from 'uuid/v4'

const StyledForm = styled(Form)`
  & .ant-form-item-with-help {
     margin-bottom: 0 !important;
  }
`

export default class SignIn extends Component {
    constructor(props) {
        super(props)

        this.handlePhoneChange = this.handlePhoneChange.bind(this)
        this.handlePhoneSubmit = this.handlePhoneSubmit.bind(this)
        this.handleCodeChange = this.handleCodeChange.bind(this)
        this.handleCodeSubmit = this.handleCodeSubmit.bind(this)
        this.handleTryAgainClick = this.handleTryAgainClick.bind(this)

        this.state = {
            step: 'phone',
            loading: false,
            phoneStep: {
                value: '',
                error: null
            },
            codeStep: null,
        }
    }

    handlePhoneChange(e) {
        const phone = e.target.value

        if (!/^[0-9]*$/.test(phone)) {
            return
        }

        this.setState(update(this.state, {
            phoneStep: {
                value: { $set: phone },
                error: {$set: null }
            }
        }))
    }

    async handlePhoneSubmit(e) {
        const { phoneStep } = this.state
        e.preventDefault()

        this.setState(update(this.state, {
            loading: { $set: true },
        }))

        try {
            await Auth.signUp( {
                username: '+' + phoneStep.value,
                password: uuid(),
            })
        } catch (err) {
            if (err.code && err.code === 'InvalidParameterException') {
                return this.setState(update(this.state, {
                    loading: { $set: false },
                    phoneStep: {
                        error: { $set: 'Please provide a valid phone number.'}
                    }
                }))
            }

            if (!err.code || err.code !== 'UsernameExistsException') {
                return this.setState(update(this.state, {
                    loading: { $set: false },
                    phoneStep: {
                        error: { $set: 'Ooops! Something went wrong.' }
                    }
                }))
            }
        }

        let challenge

        try {
            challenge = await Auth.signIn('+' + phoneStep.value)
        } catch (err) {
            return this.setState(update(this.state, {
                loading: { $set: false },
                phoneStep: {
                    error: { $set: 'Ooops! Something went wrong.' }
                }
            }))
        }

        this.setState(update(this.state, {
            step : { $set: 'code' },
            loading: { $set: false },
            phoneStep: { $set: null },
            codeStep: {
                $set: {
                    challenge: challenge,
                    error: null,
                    value: ''
                }
            }
        }))
    }

    handleCodeChange(e) {
        if (!/^[0-9]*$/.test(e.target.value)) {
            return
        }

        this.setState(update(this.state, {
            codeStep: {
                value: { $set: e.target.value },
                error: { $set: null }
            }
        }))
    }

    async handleCodeSubmit(e) {
        const { codeStep } = this.state
        e.preventDefault()

        this.setState(update(this.state, {
            loading: { $set: true },
        }))

        try {
            await Auth.sendCustomChallengeAnswer(codeStep.challenge, codeStep.value)
            await Auth.currentSession()
            await Auth.currentUserInfo()
        } catch (err) {
            if (err === 'No current user') {
                return this.setState(update(this.state, {
                    loading: { $set: false },
                    codeStep: {
                        error: { $set: 'Please provide a valid code.' }
                    }
                }))
            }

            if (err.code && err.code === 'NotAuthorizedException') {
                return this.setState(update(this.state, {
                    step: { $set: 'failed' },
                    loading: { $set: false },
                    emailStep: { $set: null },
                    codeStep: { $set: null },
                }))
            }

            return this.setState(update(this.state, {
                loading: { $set: false },
                codeStep: {
                    error: { $set: 'Ooops! Something went wrong.' }
                }
            }))
        }
    }

    handleTryAgainClick() {
        this.setState({
            step: 'phone',
            loading: false,
            phoneStep: {
                value: '',
                error: null
            },
            codeStep: null,
        })
    }

    render() {
        const { step, loading, phoneStep, codeStep } = this.state

        if (step === 'phone') {
            return (
                <Modal centered closable={false} footer={null} title='Charge Contractors Tool' visible={true}>
                    <p>Welcome to Charge. If you have a Contractors Account, please provide your authorized phone number (e.g. +1..) to sign in.</p>
                    <div style={{padding: '0 0 16px 0', textAlign: 'center'}}>
                        <StyledForm layout='inline' onSubmit={this.handlePhoneSubmit}>
                            <Form.Item help={phoneStep.error} validateStatus={phoneStep.error ? 'error' : null}>
                                <Input addonBefore='+' disabled={loading} onChange={this.handlePhoneChange} placeholder='12223334444' style={{width: '10rem'}} value={phoneStep.value}/>
                            </Form.Item>
                            <Form.Item>
                                <Button disabled={!phoneStep.value.length} htmlType='submit' loading={loading} type='primary'>Sign In</Button>
                            </Form.Item>
                        </StyledForm>
                    </div>
                    <p style={{marginBottom: '0'}}>Never heard of Charge? <a href='https://charge.us'>Learn more!</a></p>
                </Modal>
            )
        }

        if (step === 'code') {
            return (
                <Modal centered closable={false} footer={null} title='Charge Contractors Tool' visible={true}>
                    <p>Please provide the single use verification code received via SMS.</p>
                    <div style={{padding: '0 0 16px 0', textAlign: 'center'}}>
                        <StyledForm layout='inline' onSubmit={this.handleCodeSubmit}>
                            <Form.Item help={codeStep.error} validateStatus={codeStep.error ? 'error' : null}>
                                <Input disabled={loading} onChange={this.handleCodeChange} placeholder='123...' style={{width: '10rem'}} value={codeStep.value}/>
                            </Form.Item>
                            <Form.Item>
                                <Button disabled={!codeStep.value} htmlType='submit' loading={loading} type='primary'>Verify</Button>
                            </Form.Item>
                        </StyledForm>
                    </div>
                </Modal>
            )
        }

        if (step === 'failed') {
            return (
                <Modal centered closable={false} footer={null} title='Charge Contractors Tool' visible={true}>
                    <p>Sorry, we are not able to authenticate you using this code. Either it is invalid, or it has expired. <Button type='link' onClick={this.handleTryAgainClick} style={{padding: '0'}}>Try again.</Button></p>
                </Modal>
            )
        }
    }
}
import Amplify from 'aws-amplify'
import mapboxgl from 'mapbox-gl'

mapboxgl.accessToken = 'pk.eyJ1IjoiY2hhcmdlLWFjY291bnRzIiwiYSI6ImNrNWQzYWFjZTFyeHIzbHFoM3Y5cDlsdm0ifQ.1-jxpSnos65qghwoD1nSTw'

const config = {
    stage: process.env.REACT_APP_STAGE,
    internalApiBaseUrl: process.env.REACT_APP_INTERNAL_API_BASE_URL,
    cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    cognitoUserPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
}

Amplify.configure({
    Analytics: {
        disabled: true
    },
    Auth: {
        region: 'us-east-1',
        userPoolId: config.cognitoUserPoolId,
        userPoolWebClientId: config.cognitoUserPoolClientId,
    }
})

export default config
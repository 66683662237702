import { Alert } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

const StyledAlert = styled(Alert)`
    margin: 5rem 1rem 1rem 1rem;
`

export default class Error extends Component {
    static propTypes = {
        message: PropTypes.string.isRequired
    }

    render() {
        return <StyledAlert message={this.props.message} type='error' />
    }
}
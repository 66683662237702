import { Layout, Button, Menu, Icon, Avatar } from 'antd'
import { Auth } from 'aws-amplify'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import AddToHomeScreen from "@ideasio/add-to-homescreen-react";
import logoUrl from '../assets/logo.png'
import "../assets/app.css"
import menuInfo from "../assets/menu_info.json"

const { Content } = Layout;
const { SubMenu } = Menu;

const MenuButton = styled(Button)`
    top: 10px;
    left: 10px;
    height: 29px;
    z-index: 1000;
`

const StyledContainer = styled(Layout)`
  min-height: 100vh;
`

const StyledSider = styled(Layout.Sider)`
  height: 100vh;
  overflow: auto;
  left: 0;
  display: block;
  position: relative;
  z-index: 9000;
`

const StyledLogo = styled.div`
  background-image: url(${logoUrl});
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  margin: 16px;
`

const StyledLayout = styled(Layout)`
  padding: 0;
  z-index: 1000;
`

const StyledUserInfo = styled.span`
  padding: 16px;
  display: inline-block;
  vertical-align: top;
  padding-top: 1px;
`

const StyledUserName = styled.span`
  padding-left: 16px;
  color: white;
`

export default class Root extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            userInfo: null
        };
    }

    async componentDidMount() {
        Auth.currentUserInfo().then(userInfo => {
            this.setState({
                userInfo: userInfo,
            });
        })
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    closeMenu = () => {
        if (this.state.collapsed === false) {
            this.setState({
                collapsed: true,
            });
        }

    }

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    }

    openLinkInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {
        const {children, navigate} = this.props
        const {userInfo, collapsed} = this.state
        const selectedKeys = this.props['*'] ? [this.props['*'].split('/').slice(0, 2).join('/')] : []

        return (
            <Fragment>
                <style>
                    {`.athPromptLogo{
                        content: url('images/maskable_icon_x48.png')
                    }`}
                </style>
                <AddToHomeScreen
                    onInit={() => console.log("OnInit AddToHomeScreen")}
                    appId='Charge Contractors Tool'
                    debug='false'
                    customPromptElements={{
                        logo: 'athPromptLogo',
                    }}
                />
                <StyledContainer path='/'>
                    <StyledSider
                        trigger={null}
                        collapsible
                        collapsed={collapsed}
                        onCollapse={this.onCollapse}
                    >
                        <div>
                            <StyledLogo alt='Charge'/>
                            <StyledUserInfo>
                                <Avatar size="large" icon="user" />
                                <StyledUserName>{userInfo ? userInfo.username: ''}</StyledUserName>
                            </StyledUserInfo>
                        </div>
                        <Menu
                            defaultSelectedKeys={['locations']}
                            theme={'dark'}
                            mode="inline"
                            selectedKeys={selectedKeys}>
                            <Menu.Item key='locations' onClick={() => navigate('/locations')}><span><Icon type='environment'/><span>Locations</span></span></Menu.Item>
                            <Menu.Item key='help' onClick={() => navigate('/help')}><span><Icon type='info-circle-o'/><span>Help</span></span></Menu.Item>
                            <SubMenu key="menu-links" title={<span><Icon type='book'/><span>Information</span></span>}>
                                {
                                    menuInfo.map((menuInfoItem) => {
                                        return (
                                            <Menu.Item key={menuInfoItem.title.replace(/\s+/g, '-').toLowerCase()} onClick={() => this.openLinkInNewTab(menuInfoItem.url)}>{menuInfoItem.title}</Menu.Item>
                                        )
                                    })
                                }
                            </SubMenu>

                            <Menu.Item key="signOut" onClick={() => Auth.signOut()}><span><Icon type='logout'/><span>Sign Out</span></span></Menu.Item>
                        </Menu>
                    </StyledSider>
                    <StyledLayout onClick={this.closeMenu}>
                        <Content>
                            <MenuButton type='primary' onClick={this.toggle}>
                                <Icon
                                    className="trigger"
                                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                />
                            </MenuButton>
                                {children}
                        </Content>
                    </StyledLayout>
                </StyledContainer>
            </Fragment>
        )
    }
}

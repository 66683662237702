import {Alert, Button, Form, Input, Typography, Spin, Modal, Radio, Icon} from 'antd'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import config from '../logic/config'
import QrReader from 'react-qr-scanner'
import {contractorsQRCodeScan} from '../logic/network'

const StyledForm = styled(Form)`
    display: flex;
    flex-flow: row wrap;
    
    & .ant-form-item-control-wrapper {
        width: 100%;
    }
`

const StyledButtonGroup = styled(Radio.Group)`
    width: 100%;
    margin-bottom: 8px;
`

const Container = styled.div`
    margin-bottom: 16px;
`

const facingModes = ['rear', 'front']

export default class QR extends Component {
    async parseCode(code) {
        let result = {
            partnerName: 'Unknown',
            partnerId: 'unknown',
            vehicleId: code
        }

        try {
            const resp = await contractorsQRCodeScan(code)
            if (resp !== null) {
                result = {
                    partnerName: resp.data.partnerDisplayName,
                    partnerId: resp.data.partnerId,
                    vehicleId: resp.data.vehicleId
                }

                if (config.stage === 'staging') {
                    result.partnerId = 'test'
                }

            }
        } catch (err) {
            console.error(err)
        }

        return result
    }

    static propTypes = {
        value: PropTypes.shape({
            partnerName: PropTypes.string.isRequired,
            partnerId: PropTypes.string.isRequired,
            vehicleId: PropTypes.string.isRequired
        }),
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    }

    static defaultProps = {
        disabled: false
    }

    constructor(props) {
        super(props)

        this.inputRef = React.createRef()
        this.qrRef = React.createRef();
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleScan = this.handleScan.bind(this)
        this.parseCode = this.parseCode.bind(this)
        this.onLoadQrReader = this.onLoadQrReader.bind(this)
        this.showQRModal = this.showQRModal.bind(this)
        this.showQRModalLegacy = this.showQRModalLegacy.bind(this)
        this.onChangeFacingMode = this.onChangeFacingMode.bind(this)
        this.openImageDialog = this.openImageDialog.bind(this)

        this.state = {
            error: null,
            loading: false,
            inputValue: '',
            delay: 100,
            resolution: 600,
            loadingQrReader: true,
            legacyMode: false,
            isOpenModal: false,
            facingMode: facingModes[0]
        }
    }

    handleChange(e) {
        this.setState(update(this.state, {
            error: {$set: null},
            inputValue: {$set: e.target.value}
        }))
    }

    async handleSubmit(e) {
        e.preventDefault()
        const {inputValue} = this.state

        const value = await this.parseCode(inputValue)

        if (value.partnerId === 'unknown') {
            return this.setState(update(this.state, {
                error: {$set: 'Unknown or unsupported scooter operator. We cannot charge this scooter.'}
            }))
        }

        this.setState(update(this.state, {
            error: {$set: null},
            inputValue: {$set: ''}
        }))

        this.props.onChange(value)
    }

    handleScan(data) {
        if (data != null) {
            this.setState(update(this.state, {
                error: {$set: null},
                inputValue: {$set: data},
                isOpenModal: {$set: false},
            }))
        }
    }

    handleError(err) {
        console.error(err)
    }

    onLoadQrReader(e) {
        this.setState({
            loadingQrReader: false,
        })
    }

    showQRModal() {
        this.setState({
            legacyMode: false,
        });

        this.setState({
            isOpenModal: true,
        });
    }

    showQRModalLegacy() {
        this.setState({
            legacyMode: true,
        });

        this.setState({
            isOpenModal: true,
        });
    }

    handleCloseModal = () => {
        this.setState({isOpenModal: false});
    }

    onChangeFacingMode(value) {
        if (value === facingModes[0]) {
            this.setState({facingMode: facingModes[0]});
        } else {
            this.setState({facingMode: facingModes[1]});
        }
    }

    openImageDialog() {
        this.qrRef.current.openImageDialog()
    }

    render() {
        const {onChange, value, disabled} = this.props
        const {
            error,
            loading,
            inputValue,
            delay,
            resolution,
            loadingQrReader,
            isOpenModal,
            facingMode,
            legacyMode
        } = this.state
        const previewStyle = {
            display: "block",
            objectFit: "cover",
            height: "300px",
            width: "300px",
            overflow: "scroll",
            margin: "auto"
        }
        const loadingQrReaderStyle = {
            margin: "20px 0",
            marginBottom: "20px",
            padding: "30px 50px",
            textAlign: "center",
        }
        return (
            <Container>
                {!value &&
                <Fragment>
                    <Typography.Text style={{display: 'block', marginBottom: '0.5rem'}} strong>Scooter QR
                        Code:</Typography.Text>
                    <StyledForm layout='inline' onSubmit={this.handleSubmit}>
                        <Form.Item help={error} validateStatus={error ? 'error' : null} style={{flexGrow: '1'}}>
                            <Input disabled={loading} onChange={this.handleChange} style={{width: '100%'}}
                                   placeholder='e.g. https://app.goflash.com/vehicle/abcd...' value={inputValue}/>
                        </Form.Item>
                        <Form.Item style={{marginRight: '5px'}}>
                            <Button disabled={!inputValue.length} htmlType='submit' loading={loading} type='primary'>Confirm
                                Code</Button>
                        </Form.Item>
                        <Form.Item style={{display: 'block', marginRight: '5px'}}>
                            <Button type="primary" onClick={this.showQRModal}><Icon type='qrcode'/>Scan</Button>
                        </Form.Item>
                        <Form.Item style={{display: 'block', marginRight: '5px'}}>
                            <Button type="primary" onClick={this.showQRModalLegacy}><Icon
                                type="picture"/>Upload</Button>
                        </Form.Item>

                    </StyledForm>
                    <Typography.Text type='secondary' style={{display: 'block', marginTop: '0.4rem'}}>Scan the scooter
                        QR code using a scanner, or paste the value here.</Typography.Text>
                    <Modal
                        centered
                        onCancel={this.handleCloseModal}
                        visible={isOpenModal}
                        title="Scan QR"
                        footer={[
                            legacyMode ?
                                <Button key="fileMode" type='primary' onClick={this.openImageDialog}>Open file</Button>
                                :
                                <StyledButtonGroup key="cameraMode" buttonStyle='solid' value={facingMode}
                                                   onChange={(e) => this.onChangeFacingMode(e.target.value)}>
                                    {facingModes.map((value) => <Radio.Button key={value}
                                                                              value={value}>{value}</Radio.Button>)}
                                </StyledButtonGroup>
                        ]}>
                        {loadingQrReader && <div style={loadingQrReaderStyle}><Spin tip="Loading..."/></div>}
                        {isOpenModal &&
                        <Fragment>
                            <QrReader
                                ref={this.qrRef}
                                facingMode={facingMode}
                                onLoad={this.onLoadQrReader}
                                delay={delay}
                                resolution={resolution}
                                style={previewStyle}
                                onError={this.handleError}
                                onScan={this.handleScan}
                                legacyMode={legacyMode}
                            />
                        </Fragment>
                        }

                    </Modal>

                </Fragment>
                }
                {value &&
                <Alert afterClose={() => onChange(null)} closable={!disabled} closeText={disabled ? null : 'Clear'}
                       message={`${value.partnerName} - Vehicle Id: ${value.vehicleId}`}/>}
            </Container>
        )
    }
}
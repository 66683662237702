import { Drawer } from 'antd'
import debounce from 'debounce-promise'
import React, { Component, Fragment } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import Error from '../components/Error'
import LocationMap from '../components/LocationMap'
import { contractorsLocationsInspect, contractorsLocationGet, simplifyError } from '../logic/network'
import Location from './Location'

const StyledDrawer = styled(Drawer)`
    & .ant-drawer-content-wrapper {
        height: calc(100vh - 10rem) !important;
    }
`

export default class Locations extends Component {
    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
        this.handleMove = debounce(this.handleMove.bind(this), 1000)
        this.handleClose = this.handleClose.bind(this)

        this.state = {
            error: null,
            locations: [],
            focusedLocation: null
        }
    }

    async handleClick(location) {
        try {
            const resp = await contractorsLocationGet('station', location.id)
            this.setState(update(this.state, {
                error: { $set: null },
                focusedLocation: { $set: resp.data.location }
            }))
        } catch (err) {
            console.error(err)
            this.setState(update(this.state, {
                focusedLocation: { $set: location }
            }))
        }

    }

    handleClose() {
        this.setState(update(this.state, {
            focusedLocation: { $set: null }
        }))
    }

    async handleMove(bounds) {
        try {
            const resp = await contractorsLocationsInspect('station', bounds)
            this.setState(update(this.state, {
                error: { $set: null },
                locations: { $set: resp.data.locations }
            }))
        } catch (err) {
            console.error(err)

            const sErr = simplifyError(err, {
                401: 'You are not authorized to view this page.',
                403: 'You are not authorized to view this page.'
            })

            this.setState(update(this.state, {
                error: { $set: sErr.message },
                locations: { $set: [] }
            }))
        }
    }

    render() {
        const { error, locations, focusedLocation } = this.state

        return (
            <Fragment>
                {error && <Error message={error} type='error' />}
                {!error &&
                    <Fragment>
                        <LocationMap locations={locations} onClick={this.handleClick} onMove={this.handleMove}/>
                        <StyledDrawer placement='bottom' title='Station' closable={true} onClose={this.handleClose} visible={focusedLocation && true}>
                            {focusedLocation && <Location location={focusedLocation}/>}
                        </StyledDrawer>
                    </Fragment>
                }
            </Fragment>
        )
    }
}
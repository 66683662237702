import { Alert, Modal } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class FatalError extends Component {
    static propTypes = {
        message: PropTypes.string.isRequired
    }

    render() {
        return (
            <Modal centered closable={false} footer={null} title='Charge Contractors Tool' visible={true}>
                <Alert message={this.props.message} type='error'/>
            </Modal>
        )
    }
}
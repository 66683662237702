/**
 * @param type must be 'lat' or 'lng'
 * @param value coordinate value
 *
 * @return valid coordinate value
 */
export function ValidateCoordinate(type, value) {
    let result = value
    switch (type) {
        case 'lat':
            result = (value < -90)? -90: result
            result = (value > 90)? 90: result
            break
        case 'lng':
            result = (value < -180)? -180: result
            result = (value > 180)? 180: result
            break
        default:
            result = value
    }

    return result
}
import {Button, Descriptions, Divider, Result, Typography} from 'antd'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import update from 'react-addons-update'
import QR from '../components/QR'
import Stall from '../components/Stall'
import {contractorsDropoffInitiate, simplifyError} from "../logic/network";

export default class Location extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)

        this.handleQRChange = this.handleQRChange.bind(this)
        this.handleStallChange = this.handleStallChange.bind(this)
        this.handleInitiateClick = this.handleInitiateClick.bind(this)
        this.handleDoneClick = this.handleDoneClick.bind(this)
        this.getFirstAvailableStall = this.getFirstAvailableStall.bind(this)

        this.state = {
            step: 'wait',
            qrValue: null,
            stallValue: this.getFirstAvailableStall(),
            loading: false,
            error: null
        }
    }

    handleQRChange(v) {
        this.setState(update(this.state, {
            qrValue: { $set: v },
            error: { $set: null }
        }))
    }

    handleStallChange(v) {
        this.setState(update(this.state, {
            stallValue: { $set: v },
            error: { $set: null }
        }))
    }

    async handleInitiateClick() {
        this.setState(update(this.state, {
            loading: { $set: true },
            error: { $set: null }
        }))

        const { location } = this.props
        const { qrValue, stallValue } = this.state

        try {
            await contractorsDropoffInitiate(location.id, stallValue, qrValue.partnerId, qrValue.vehicleId)
            let firstAvailableStall = this.getFirstAvailableStall()
            this.setState(update(this.state, {
                step: { $set: 'confirm' },
                qrValue: { $set: null },
                stallValue:  { $set: firstAvailableStall },
                loading: { $set: false }
            }))
        } catch (err) {
            console.error(err)

            const sErr = simplifyError(err, {
                401: 'You are not authorized to view this page.',
                403: 'You are not authorized to view this page.',
                409: 'Stall is not available.'
            })

            this.setState(update(this.state, {
                error: { $set: sErr.message },
                loading: { $set: false }
            }))
        }
    }

    handleDoneClick() {
        let firstAvailableStall = this.getFirstAvailableStall()
        this.setState(update(this.state, {
            step: { $set: 'wait' },
            qrValue: { $set: null },
            stallValue:  { $set: firstAvailableStall },
            loading: { $set: false }
        }))
    }

    getFirstAvailableStall() {
        const { location } = this.props
        let stallIndex = 1
        if (location.availableStallsList.length > 0) {
            stallIndex = location.availableStallsList[0]
            stallIndex++
        }

        return stallIndex
    }

    render() {
        const { location } = this.props
        const { step, qrValue, loading, stallValue,  error } = this.state

        return (
            <Fragment>
                <Descriptions column={1} layout='vertical' size='small'>
                    <Descriptions.Item label='Address'><a href={`https://www.google.com/maps/search/?api=1&query=${location.coordinates.lat},${location.coordinates.lng}`}>{location.address}</a></Descriptions.Item>
                    <Descriptions.Item label='Available Stalls'>{location.stallsAvailable} of {location.stallsTotal}</Descriptions.Item>
                </Descriptions>
                <Divider>Drop Off</Divider>
                {step === 'wait' &&
                    <Fragment>
                        <QR disabled={loading} value={qrValue} onChange={this.handleQRChange}/>
                        <Stall disabled={loading} value={stallValue} stallsTotal={location.stallsTotal} availableStallsList={location.availableStallsList} onChange={this.handleStallChange}/>
                        <Button type='primary' style={{width: '100%', marginTop: '1rem'}} onClick={this.handleInitiateClick} loading={loading} disabled={qrValue === null}>Initiate Drop-Off</Button>
                        {error && <Typography.Text type='danger'>{error}</Typography.Text>}
                    </Fragment>
                }
                {step === 'confirm' &&
                    <Fragment>
                        <Result title='Ready to Plug In' extra={
                            <Fragment>
                                <Typography.Text type='secondary' style={{display: 'block', marginTop: '0.2rem'}}>The stall light should turn on white. Please go ahead and plug in the scooter.</Typography.Text>
                                <br/>
                                <Button onClick={this.handleDoneClick}>Done</Button>
                            </Fragment>
                        } />
                    </Fragment>
                }
            </Fragment>
        )
    }
}
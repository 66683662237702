import { Auth } from 'aws-amplify'
import axios from 'axios'
import config from './config'
import {ValidateCoordinate} from './validation'

/**
 * @param locationType Must be "station".
 * @param bounds Must be a MapBox Bounds object.
 */
export async function contractorsLocationsInspect(locationType, bounds) {
    return axios({
        method: 'post',
        url:  `${config.internalApiBaseUrl}/contractors/locationsInspect`,
        headers: {
            Authorization: `Bearer ${await getToken()}`
        },
        data: {
            type: locationType,
            southWest: {
                lat: ValidateCoordinate('lat', bounds.getSouthWest().lat),
                lng: ValidateCoordinate('lng', bounds.getSouthWest().lng)
            },
            northEast: {
                lat: ValidateCoordinate('lat', bounds.getNorthEast().lat),
                lng: ValidateCoordinate('lng', bounds.getNorthEast().lng)
            }
        }
    })
}


/**
 * @param locationType Must be "station".
 * @param locationID Must be a location ID.
 */
export async function contractorsLocationGet(locationType, locationID) {
    console.log(locationType, locationID)
    return axios({
        method: 'post',
        url:  `${config.internalApiBaseUrl}/contractors/location`,
        headers: {
            Authorization: `Bearer ${await getToken()}`
        },
        data: {
            type: locationType,
            locationId: locationID
        }
    })
}


/**
 * @param code QR code string representation.
 */
export async function contractorsQRCodeScan(code) {
    return axios({
        method: 'post',
        url:  `${config.internalApiBaseUrl}/contractors/codeParse`,
        headers: {
            Authorization: `Bearer ${await getToken()}`
        },
        data: {
            code
        }
    })
}

/**
 * @param locationId Id of the location the scooter is being dropped off at.
 * @param stallNumber Stall number selected for drop-off at the location.
 * @param partnerId Id of the operator who owns the scooter for billing (e.g. lime, circ, etc.).
 * @param vehicleId Id of the vehicle being dropped off.
 */
export async function contractorsDropoffInitiate(locationId, stallNumber, partnerId, vehicleId) {
    return axios({
        method: 'post',
        url:  `${config.internalApiBaseUrl}/contractors/dropoffInitiate`,
        headers: {
            Authorization: `Bearer ${await getToken()}`
        },
        data: {
            locationId,
            stallNumber,
            partnerId,
            vehicleId
        }
    })
}

export function simplifyError(err, statusMap) {
    console.log(err)
    let nErr

    if (!err.response || !err.response.status || !statusMap[err.response.status]) {
        nErr = new Error('Ooops, something went wrong. Please try to refresh the page.')
    } else {
        nErr = new Error(statusMap[err.response.status])
    }

    if (err.response && err.response.status) {
        nErr.status = err.response.status
    }

    return nErr
}

async function getToken() {
    try {
        const session = await Auth.currentSession()
        return '' + session.idToken.jwtToken
    } catch (err) {
        console.error(err)
        await Auth.signOut()
    }
}
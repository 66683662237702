import { Radio, Typography } from 'antd'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'

const StyledButtonGroup = styled(Radio.Group)`
    width: 100%;
    margin-bottom: 8px;
`

export default class Stall extends Component {
    static propTypes = {
        availableStallsList: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,
        stallsTotal: PropTypes.number.isRequired,
        disabled: PropTypes.bool
    }

    static defaultProps = {
        disabled: false
    }

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        this.props.onChange(e.target.value)
    }

    render() {
        const { disabled, value, availableStallsList, stallsTotal } = this.props

        return (
            <Fragment>
                <Typography.Text style={{display: 'block', marginBottom: '0.7rem'}} strong>Station Stall:</Typography.Text>
                <StyledButtonGroup disabled={disabled} buttonStyle='solid' value={value >= 1 && value <= 6 ? value : null } onChange={this.handleChange}>
                    <Radio.Button value={1} disabled={!availableStallsList.includes(0)}>01</Radio.Button>
                    <Radio.Button value={2} disabled={!availableStallsList.includes(1)}>02</Radio.Button>
                    <Radio.Button value={3} disabled={!availableStallsList.includes(2)}>03</Radio.Button>
                    <Radio.Button value={4} disabled={!availableStallsList.includes(3)}>04</Radio.Button>
                    <Radio.Button value={5} disabled={!availableStallsList.includes(4)}>05</Radio.Button>
                    <Radio.Button value={6} disabled={!availableStallsList.includes(5)}>06</Radio.Button>
                </StyledButtonGroup>
                {(stallsTotal > 6) ? <StyledButtonGroup disabled={disabled} buttonStyle='solid' value={value >= 7 && value <= 12 ? value : null } onChange={this.handleChange}>
                    <Radio.Button value={7} disabled={!availableStallsList.includes(6)}>07</Radio.Button>
                    <Radio.Button value={8} disabled={!availableStallsList.includes(7)}>08</Radio.Button>
                    <Radio.Button value={9} disabled={!availableStallsList.includes(8)}>09</Radio.Button>
                    <Radio.Button value={10} disabled={!availableStallsList.includes(9)}>10</Radio.Button>
                    <Radio.Button value={11} disabled={!availableStallsList.includes(10)}>11</Radio.Button>
                    <Radio.Button value={12} disabled={!availableStallsList.includes(11)}>12</Radio.Button>
                </StyledButtonGroup> : ""}
            </Fragment>
        )
    }
}
import { GeolocateControl, Map, Marker } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class LocationMap extends Component {
    static propTypes = {
        locations: PropTypes.array.isRequired,
        onClick: PropTypes.func.isRequired,
        onMove: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)
        this.markers = {}
        this.handleClick = this.handleClick.bind(this)

        this.state = {
            locationLat: 48.85940396758846,
            locationLng: 2.3358289884739634,
        };

    }


    componentDidMount() {
        this.map = new Map({
            container: this.container,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: {
                lng: this.state.locationLng,
                lat: this.state.locationLat
            },
            zoom: 12
        })

        this.geolocateControl = new GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            fitBoundsOptions: {
                maxZoom: 12
            }
        })

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                this.map.jumpTo({
                    center: [pos.coords.longitude, pos.coords.latitude],
                })
            });
        }

        this.map.addControl(this.geolocateControl, 'top-right')
        this.map.on('move', () => this.props.onMove(this.map.getBounds()))
        this.props.onMove(this.map.getBounds())
    }

    componentDidUpdate() {
        this.props.locations.forEach(l => {
            if (!this.markers[l.id]) {
                const m = new Marker({ color: 'rgb(24, 144, 255)' }).setLngLat([l.coordinates.lng, l.coordinates.lat]).addTo(this.map)
                m.location = l
                m.getElement().addEventListener('click', () => this.handleClick(l.id))
                m.getElement().style.cursor = 'pointer'
                this.markers[l.id] = m
            } else {
                this.markers[l.id].location = l
            }
        })
    }

    componentWillUnmount() {
        this.map.remove()
    }

    handleClick(locationId) {
        const m = this.markers[locationId]

        this.map.jumpTo({
            center: m.getLngLat(),
            zoom: 15
        })

        const c = this.map.getCanvas()
        const p = this.map.project(m.getLngLat())

        this.map.jumpTo({
            center: this.map.unproject([p.x, c.height / 2 - 50]),
            zoom: 15
        })

        this.props.onClick(m.location)
    }

    render() {
        return (
            <div ref={el => this.container = el} style={{width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0}}/>
        )
    }
}